import { FC, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SoundButton } from "../../../../components/SoundButton";
import { usePlaySessionContext } from "../../../../contexts/play-session/usePlaySessionContext";

interface BottonSheetModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => any;
  children?: any;
  cancelText?: string;
  submitText?: string;
  submitBackgroundColor?: string;
  button_font_color?: string;
}

export const BottonSheetModal: FC<BottonSheetModalProps> = ({
  isOpen = false,
  onClose = () => {
    console.warn("please write onClose");
  },
  onSubmit,
  children,
  cancelText = "취소",
  submitText = "제출",
  submitBackgroundColor,
  button_font_color,
}) => {
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const isProcess = useRef(false);

  const handleClose = () => {
    if (isProcess.current === true) {
      return;
    }
    onClose();
  };
  const handleSubmit = () => {
    if (isProcess.current === true) {
      return;
    }
    if (!onSubmit) {
      onClose();
      return;
    }
    isProcess.current = true;
    const res = onSubmit();
    if (res instanceof Promise) {
      res
        .then(() => onClose())
        .catch(() => {})
        .finally(() => (isProcess.current = false));
    } else {
      isProcess.current = false;
    }
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-hidden flex items-end">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-8"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-8"
          >
            <div className="bg-white flex-1 rounded-t-3xl">
              <div>{children}</div>
              <div
                style={{ padding: "26px 20px" }}
                className="flex space-x-2.5"
              >
                <SoundButton
                  type="button"
                  className="pink-btn-mo flex-1"
                  onClick={handleClose}
                  playSound={shouldPlaySound}
                >
                  {cancelText}
                </SoundButton>
                <SoundButton
                  type="button"
                  className="blue-btn-mo flex-1"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: submitBackgroundColor
                      ? submitBackgroundColor
                      : undefined,
                    color: button_font_color ? button_font_color : undefined,
                  }}
                  playSound={shouldPlaySound}
                >
                  {submitText}
                </SoundButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
